<template>
  <div class="app-container">
    <el-radio v-model="queryType" label="1">直客</el-radio>
    <el-radio v-model="queryType" label="2">代理</el-radio>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="rank" label="名次"/>
      <el-table-column v-if="queryType=='1'" prop="custId" label="客户ID"/>
      <el-table-column prop="userName" label="用户名"/>
      <el-table-column v-if="queryType=='1'" prop="login" label="交易账号"/>
      <el-table-column v-else prop="custId" label="代理名"/>
      <el-table-column prop="contractSize" label="交易手数"/>
      <el-table-column prop="commissionAmount" label="佣金贡献"/>
      <el-table-column prop="netDeposit" label="净入金"/>
      <el-table-column prop="manager" label="上级信息">
        <template slot-scope="scope">
            <span >{{scope.row.belongId}}<br></span>
            <span >{{scope.row.belongName}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="lastTranTime" label="最近交易时间"/>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
export default {
  mixins: [initData],
  props:['queryInfo','refresh','query'],
  data() {
    return {
      statusLoading: false,
      sup_this: this,
      queryType:'1'
    }
  },
  watch :{
    refresh(news,olds) {
      this.init()
    },
    queryType(news,olds) {
      this.init()
    }

  },
  created() {
    this.$nextTick(() => {
      // this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = '/crm/tMerchantCommissionOrder/queryCommContribution'
      const sort = 'id,desc'
      this.params = {}
      this.params = {
        pageNum: this.page,
        pageSize: this.size,
        sort: sort,
        queryType:this.queryType
      }
      const query = this.queryInfo
      const type = query.type
      const queryCon = query.queryCon
      let startTime = query.startTime
      let endTime = query.endTime
      if (type!=null && type!='') {
          if(type == 1) {
            this.params['managerInfo'] = queryCon
          }else if(type == 2) {
            this.params['merId'] = queryCon
          }
      }
      if (endTime!=null && endTime!='') {
        endTime = endTime.replace(/-/g,'')
        this.params['endTime'] = endTime.substring(0,8)
      }
      if (startTime!=null&&startTime!='') {
        startTime = startTime.replace(/-/g,'')
        this.params['startTime'] = startTime.substring(0,8)
      }
      if(this.query.managerInfo){
        this.params['managerInfo'] = this.query.managerInfo;
      }
      this.query.merId && (this.params.merId = this.query.merId);
      return true
    }
  }
}
</script>

<style scoped>

</style>
