<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-select
            v-model="query.merId"
            clearable
            placeholder="类型"
            class="filter-item"
            style="width: 130px"
    >
      <el-option
              v-for="item in queryTypes"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
      />
    </el-select>
    <el-input
            class="filter-item"
            v-model="query.managerInfo"
            clearable
            placeholder="查询条件"
            style="width: 200px;"
    />
    从
     <el-date-picker
      value-format="yyyy-MM-dd HH:mm:ss"
      format="yyyy-MM-dd HH:mm:ss"
      class="filter-item"
      style="width: 200px;"
      v-model="query.startTime"
       type="datetime"
      placeholder="开始日期">
    </el-date-picker>
    到
    <el-date-picker
      value-format="yyyy-MM-dd HH:mm:ss"
      format="yyyy-MM-dd HH:mm:ss"
      class="filter-item"
      style="width: 200px;"
      v-model="query.endTime"
      type="datetime"
      placeholder="结束日期">
    </el-date-picker>
    <span v-if="showErr">{{errMsg}}</span>
    <el-button
            v-if="checkPermission(['ADMIN','COMMISSIONORDERSSIONORDER_ALL','COMMISSIONORDERSSIONORDER_SELECT'])"
            class="filter-item"
            size="mini"
            type="primary"
            icon="el-icon-search"
            @click="toQuery">搜索</el-button>
    <el-button
            v-if="checkPermission(['ADMIN','COMMISSIONORDERSSIONORDER_ALL','COMMISSIONORDERSSIONORDER_SELECT'])"
            class="filter-item"
            size="mini"
            type="primary"
            icon="el-icon-search"
            @click="clear">清空</el-button>
    <el-button
      v-if="checkPermission(['ADMIN', 'EXPORT_COMMISSION_RECORD'])"
      class="filter-item"
      size="mini"
      type="primary"
      icon="el-icon-download"
      @click="exportHandle"
    >导出</el-button>
  </div>
</template>

<script>
  import { calcDuringDate } from '@/utils/index'
import checkPermission from '@/utils/permission' // 权限判断函数
export default {
  props: {
    query: {
      type: Object,
      required: true
    },
  },
  data() {
     return  {
       queryTypes:[
         {key:1,display_name:'代理ID'},
         {key:2,display_name:'客户经理姓名'},
         {key:3,display_name:'订单号'},
         {key:4,display_name:'交易账号'}
       ],
       errMsg:'',
       showErr:false,
       loading: false,
     }
  },
  methods: {
    checkPermission,
    toQuery() {
      /*if(this.query.type == undefined
          || this.query.queryCon == undefined
          || this.query.type == 'null'
          || this.query.queryCon == '' ){
            this.errMsg = '请输入查询条件'
            this.showErr = true
      }else {
        this.errMsg = ''
        this.showErr = false
        this.$parent.handleQuery()
      }*/
      this.showErr = false;
      if(!this.query.merId || !this.query.managerInfo){
        this.$message({
          message: '请输入类型和查询条件',
          type: 'warning'
        });
        return;
      }
      if(this.query.startTime && this.query.endTime){
        let duringDays = calcDuringDate({
          endTime: this.query.endTime,
          startTime: this.query.startTime,
        });
        if(duringDays > 30){
          this.$message({
            message: '开始日期和结束日期不能超过30天',
            type: 'warning'
          });
          return;
        }
      }
      this.$parent.handleQuery()
    },
    exportHandle(){
      this.$emit('export-handle')
    },
    clear(){
      this.$emit('clear');
    }
  }
}
</script>
