<template>
  <div class="app-container">
    <blockquote class="my-blockquote">统计信息：</blockquote>
    <el-row>
      <el-col :span="8"><span class="label">佣金总额: </span> <span>{{ sumInfo.commAmtSum}}</span></el-col>
      <el-col :span="8"><span class="label">待出佣金额: </span> <span>{{ sumInfo.applyAmtSum }}</span></el-col>
      <el-col :span="8"><span class="label">可出佣金额: </span> <span>{{ sumInfo.remainAmtSum }}</span></el-col>
    </el-row>
    <blockquote class="my-blockquote">申请明细：</blockquote>
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="Id"/>
      <el-table-column prop="merchantId" label="申请人信息"/>
      <el-table-column prop="type" label="出佣类型">
        <template slot-scope="scope">
          <span>{{ parseCommiType(scope.row.payTypeId) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="出佣金额"/>
      <el-table-column prop="currency" label="出佣币种">
        <template slot-scope="scope">
          <span>{{ scope.row.type==1?parseCurrencyType(scope.row.currency):parseCurrencyType(1) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="userName" label="收款人"/>
      <el-table-column prop="manager" label="客户经理">
        <template slot-scope="scope">
            <span v-if="scope.row.managerName!=null">{{scope.row.managerName}}<br></span>
            <span v-if="scope.row.managerId!=null">{{scope.row.managerId}}</span>
            <span v-if="scope.row.managerName==null&&scope.row.managerId==null">无</span>
        </template>
      </el-table-column>
      <el-table-column prop="chkStatus" label="审核状态">
        <template slot-scope="scope">
          <span>{{ parseCommiStatus(scope.row.chkStatus) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="申请时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import { queryCommStat } from '@/api/ib/ibMerchantCommission'
import { parseCommiStatus,parseCommiType,parseCurrencyType } from '@/views/pt/ib/utils/index'
export default {
  mixins: [initData],
  props:['queryInfo','refresh'],
  data() {
    return {
      statusLoading: false,
      sup_this: this,
      sumInfo:[]
    }
  },
  created() {
    this.$nextTick(() => {
      // this.init()
      // this.getSumData()
    })
  },
   watch :{
    refresh(news,olds) {
      this.init()
      this.getSumData()
    }
  },
  methods: {
    parseTime,
    checkPermission,
    parseCommiStatus,
    parseCommiType,
    parseCurrencyType,
    getCommiType(type){
      switch (type) {
        case 1:
          return '';
        case 2:
          return '';
        case 3:
          return '';
      }
    },
    getSumData() {
      const params={
        merId: this.queryInfo.merId,
        managerInfo: this.queryInfo.managerInfo,
      }
      queryCommStat(params).then(res=>{
          this.sumInfo=res.content[0]
      })
    },
    beforeInit() {
      this.url = '/crm/tMerchantCommission'
      const sort = 'id,desc'
      this.params = {}
      this.params = {
        page: this.page,
        size: this.size,
        sort: sort,
        merId: this.queryInfo.merId,
        managerInfo: this.queryInfo.managerInfo,
        startTime: this.queryInfo.startTime,
        endTime: this.queryInfo.endTime,
      }
      return true
    }
  }
}
</script>

<style scoped>
.el-row {
    display: flex;
    align-items: center;
    padding: 10px 0;
  }
.el-col {
    border-radius: 4px;
  }
.label {
    font-size: 18px;
    padding: 10px 10px;
  }
</style>
