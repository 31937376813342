<template>
  <div class="app-container">
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="orderId" label="订单号"/>
      <el-table-column prop="mtAcc" label="交易账号"/>
      <el-table-column prop="merId" label="代理ID"/>
      <el-table-column prop="managerName" label="客户经理"/>
<!--      需要加入Stock品种 todo-->
      <el-table-column prop="currencyPair" label="品种"/>
      <el-table-column prop="contractSize" label="手数"/>
      <el-table-column prop="innerAmt" label="内佣"/>
      <el-table-column prop="outterAmt" label="外佣"/>
      <el-table-column prop="commAmt" label="佣金"/>
      <el-table-column prop="closeTime" label="平仓时间"/>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
export default {
  mixins: [initData],
  props:['queryInfo','refresh'],
  data() {
    return {
      statusLoading: false,
      sup_this: this
    }
  },
  created() {
    this.$nextTick(() => {
      // this.init()
    })
  },
  watch :{
    refresh(news,olds) {
      this.init()
    },
    queryInfo:{
      handler(){
        // this.page = 0;
        // this.init();
      },
      deep: true,
    }
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = '/crm/tMerchantCommissionOrder/queryCommDetail'
      const sort = 'id,desc'
      this.params = {}

      this.params = {
        pageNum: this.page,
        pageSize: this.size,
        sort: sort,
        merId: this.queryInfo.merId,
        managerInfo: this.queryInfo.managerInfo,
        startTime: this.queryInfo.startTime,
        endTime: this.queryInfo.endTime,
      }

      if (this.queryInfo.type) {
        if (this.queryInfo.type == 1) {
          this.params['managerInfo'] = this.queryInfo.queryCon;
          this.params['merId'] = 2;
        } else if (this.queryInfo.type == 2) {
          this.params['merId'] = 1;
          this.params['managerInfo'] = this.queryInfo.queryCon;
        }
      }
      return true
    }
  }
}
</script>

<style scoped>

</style>
