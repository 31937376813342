<template>
  <div class="app-container">
<!--所有佣金数据统计保留4位小数,(去掉无效的0)-->
   <eHeader
           ref="header"
           :query="query"
           @clear="clear"
           @export-handle="exportHandle"
   />
   <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="佣金明细" name="first">
        <commitable
                :queryInfo='queryInfo'
                :refresh='refresh'
        />
      </el-tab-pane>
      <el-tab-pane label="佣金统计" name="second">
       <commisum
               :queryInfo='queryInfo'
               :refresh='refresh'
       />
      </el-tab-pane>
      <el-tab-pane label="佣金贡献分析" name="third">
       <commirank
               :queryInfo='queryInfo'
               :query="query"
               :refresh='refresh'
       />
      </el-tab-pane>
  </el-tabs>
  </div>
</template>

<script>
import commitable from '@/components/finance/merchantcommission/commidetail/commitable'
import commisum from '@/components/finance/merchantcommission/commidetail/commisum'
import commirank from '@/components/finance/merchantcommission/commidetail/commirank'
import eHeader from '@/components/finance/merchantcommission/commidetail/header'
import { export_json_to_excel,formatJson } from '@/vendor/Export2Excel'
import { initData } from '@/api/data'
import checkPermission from '@/utils/permission'
import { parseTime } from '@/utils/index'
export default {
  name:'commidetail',
  components: { commitable,commisum,commirank,eHeader },
  created(){
    if(this.$route.query!=null) {
      let type = this.$route.query.type
      let queryCon = this.$route.query.queryCon
      this.queryInfo = {
        managerInfo : queryCon
      }
      this.query = {
        managerInfo : queryCon
      }
      if(type == 2){
        this.queryInfo.merId = 1;
        this.query.merId = 1;
      }else if(type == 1){
        this.queryInfo.merId = 2;
        this.query.merId = 2;
      }
      this.$route.query.queryCon = '';
      this.$route.query.type = '';
      this.refresh=!this.refresh
    }
  },
  data(){
    return {
      activeName:'first',
      queryInfo:{},
      query:{},
      refresh:true
    }
  },
  methods:{
    parseTime,
    checkPermission,
    export_json_to_excel,
    formatJson,
    initData,
    handleQuery() {
        this.queryInfo=this.query
        this.refresh=!this.refresh
    },
    exportHandle(){
      this.params = {
        merId: this.query.merId,
        managerInfo: this.query.managerInfo,
        startTime: this.query.startTime,
        endTime: this.query.endTime,
      }
      this.initData('/crm/tMerchantCommissionOrder/queryCommDetail', this.params).then(res => {
        this.$refs['header'].loading =false
        const tHeader =['订单号','交易账号','代理号','客户经理','品种','手数', '内佣($)','外佣($)','佣金($)','平仓时间'];
        const filterVal =['orderId','mtAcc','merId','managerName','currencyPair', 'contractSize','innerAmt','outterAmt','commAmt','closeTime'];
        const exportData = formatJson(filterVal,res.content)
        let fileName='佣金明细报表'
        const query = this.query
        const type = query.type
        const custId = query.custId
        const startTime = this.parseTime(query.startTime)
        const endTime = this.parseTime(query.endTime)
        if (type!=null && type!='') { fileName = fileName+'-'+type }
        if (custId!=null && custId!='') { fileName = fileName+'-'+custId }
        if (startTime!=null && startTime!='') { fileName = fileName+'-'+this.parseTime(query.startTime) }
        if (endTime!=null && endTime!='') { fileName = fileName+'-'+this.parseTime(query.endTime) }
        export_json_to_excel(tHeader,exportData,fileName);
      }).catch(err => {
        this.$refs['header'].loading =false
      })
    },
    clear(){
      this.query = {};
      this.queryInfo = {};
      this.handleQuery();
    }
  }
}
</script>

<style scoped>

</style>
