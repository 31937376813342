import { render, staticRenderFns } from "./commitable.vue?vue&type=template&id=986d1ec8&scoped=true&"
import script from "./commitable.vue?vue&type=script&lang=js&"
export * from "./commitable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "986d1ec8",
  null
  
)

export default component.exports